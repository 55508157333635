<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('language.edit-language') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col class="px-4 centralContent">
          <b-overlay :show="showOverlay">
            <LanguageForm @hideOverlay="hideOver" />
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import LanguageForm from '@/components/admin/langauge/LanguageForm.vue'

export default {
  name : "EditLanguage",
  components : { NavBar, LanguageForm },
  data: function() {
    return {
      showOverlay : true
    }
  },
  created: function () {
  },
  methods: {
    hideOver: function() {
      this.showOverlay = false;
    }
  }

}
</script>
<style></style>
